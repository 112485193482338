<template>
  <div class="log-manage">
    <!-- 导航提示标题 -->
    <ml-tip :showHeader="false" content="短信模版" />
    <!-- 搜索框 -->
    <ml-tip :showHeader="false" style="border: 0">
      <ml-form inline labelWidth="100px" :model="searchData" ref="searchDataRef">
        <!-- 操作类型 -->
        <ml-input
          prop="titleLike"
          placeholder="请输入短信标题"
          style="margin-bottom: 0"
          label="短信标题:"
          v-model="searchData.titleLike"
        />
        <!-- 日期 -->
        <ml-date-picker
          prop="date"
          startPlaceholder="开始日期"
          endPlaceholder="结束日期"
          label="添加时间:"
          type="datetimerange"
          v-model="searchData.date"
          :style="{ 'margin-bottom': 0, 'margin-right': '40px' }"
        />
        <!-- 搜索按钮 -->
        <ml-button
          :style="{ 'margin-bottom': 0 }"
          submitText="搜索"
          submitIcon="el-icon-search"
          cancelText="重置"
          cancelIcon="el-icon-refresh"
          cancelType="danger"
          @click-cancel="resetSearchData"
          @click-submit="searchFn"
        />
        <!-- 搜索按钮 -->
        <ml-button
          :style="{ 'margin-bottom': 0 }"
          submitText="添加模版"
          submitIcon="el-icon-plus"
          :showCancel="false"
          @click-submit="clickSmes"
        />
      </ml-form>
    </ml-tip>
    <!-- 数据表格 -->
    <ml-tip :showHeader="false" class="default-tip" style="margin-bottom: 0">
      <!-- 表格 -->
      <el-table :data="tabberData.data" style="width: 100%" :height="tabberHeight(319)">
        <el-table-column align="center" type="index" width="80">
          <template #default="scope">
            {{ (tabberData.page - 1) * tabberData.size + (scope.$index + 1) }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="模版名称" prop="templateName" min-width="10%" />
        <el-table-column align="center" label="消息标题" prop="title" min-width="10%" />

        <el-table-column align="center" label="添加时间" prop="createTime" min-width="40%" />
        <el-table-column align="center" label="模版内容" prop="content" min-width="40%" />
        <el-table-column align="center" label="操作" priop="templateId" min-width="20%">
          <template #default="scope">
            <el-button
              size="mini"
              icon="el-icon-edit"
              type="primary"
              @click="clickEdit(scope.$index, scope.row)"
            >
              修改
            </el-button>
            <el-button
              size="mini"
              icon="el-icon-delete"
              type="danger"
              @click="clickDelete(scope.$index, scope.row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <ml-pagination
        :total="tabberData.total"
        :page="tabberData.page"
        v-model="tabberData.page"
        :size="tabberData.size"
        @current-change="currentChange"
        @size-change="sizeChange"
      />
    </ml-tip>
    <!-- 发送消息提示框 -->
    <ml-dialog
      width="600px"
      ref="smesDialogRef"
      :title="smesDialogData.title"
      showClose
      @click-submit="submitSmesDialog"
    >
      <template #body>
        <ml-form
          labelWidth="120px"
          style="width: 100%"
          :model="submitSmesDialogForm"
          :rules="submitSmesDialogFormRules"
          ref="submitSmesDialogFormRef"
        >
          <ml-input
            prop="templateName"
            label="模版名称:"
            placeholder="请输入模版名称"
            v-model.trim="submitSmesDialogForm.templateName"
          />

          <ml-input
            prop="title"
            label="推送标题:"
            placeholder="请输入推送标题"
            v-model.trim="submitSmesDialogForm.title"
          />

          <ml-input
            prop="content"
            type="textarea"
            :rows="5"
            label="消息内容"
            placeholder="请输入消息内容"
            v-model.trim="submitSmesDialogForm.content"
          />
        </ml-form>
      </template>
    </ml-dialog>
    <!-- 删除设备提示框 -->
    <ml-dialog
      ref="deleteSmsTemplatesDialogRef"
      content="确定删除该模版吗？"
      @click-submit="deleteSmsTemplatesDialogSubmit"
    />
  </div>
</template>

<script>
import { onMounted, reactive, ref, watch } from 'vue'
import { tabberHeight, searchParams, getTabberData, formetData, replacePerCent } from '@/utils'
import { useStore } from 'vuex'

export default {
  name: 'SmsTemplates',
  setup() {
    const { commit, dispatch } = useStore()
    /* 开始 搜索 **********************************/
    // 搜索条件
    const searchDataRef = ref()
    const searchData = reactive({
      titleLike: '',
      date: []
    })
    // 重置搜索内容
    const resetSearchData = () => {
      searchDataRef.value.CustomFormRef.resetFields()
      tabberData.page === 1 ? getLogTabberData() : (tabberData.page = 1)
    }
    // 搜索
    const searchFn = () => {
      tabberData.page === 1 ? getLogTabberData() : (tabberData.page = 1)
    }
    /* 结束 搜索 **********************************/

    /* 开始 数据列表 **********************************/
    // 表格配置
    const tabberData = reactive({
      data: [],
      total: 0,
      page: 1,
      size: 10
    })

    // 获取列表
    const getLogTabberData = async (
      params = { pageIndex: tabberData.page, pageSize: tabberData.size }
    ) => {
      const searchDataParams = searchParams(searchData, ['date'])
      if (searchData.date.length > 0) {
        searchDataParams.startTime = formetData(searchData.date[0])
        searchDataParams.endTime = formetData(searchData.date[1])
      }
      searchDataParams.titleLike = replacePerCent(searchData.titleLike)
      const { data, total } = await getTabberData(
        dispatch,
        'fetchGetSmsTemplates',
        params,
        searchDataParams
      )
      tabberData.data = data || []
      tabberData.total = total || 0
    }
    //分页事件
    watch([() => tabberData.page, () => tabberData.size], () => {
      tabberData.page === 1 ? getLogTabberData() : (tabberData.page = 1)
    })
    const currentChange = page => {
      tabberData.page = page
    }
    const sizeChange = size => {
      tabberData.size = size
    }
    /* 结束 数据列表 **********************************/

    // 发送消息
    const smesDialogRef = ref()
    const smesDialogData = reactive({
      title: '',
      paramsName: '',
      templateId: ''
    })
    // 打开发送
    const clickEdit = async (index, row) => {
      const data = await dispatch('fetchGetSmsTemplate', row.templateId)
      if (data && data.code === 200) {
        const { templateName, title, content, templateId } = data.data
        submitSmesDialogForm.templateName = templateName
        submitSmesDialogForm.title = title
        submitSmesDialogForm.content = content
        smesDialogData.templateId = templateId
      }
      clickSmes(true)
    }
    const clickSmes = isEdit => {
      smesDialogData.paramsName = 'fetchAddSmsTemplate'
      smesDialogData.title = '添加模版'
      if (isEdit) {
        smesDialogData.paramsName = 'fetchUpdateSmsTemplate'
        smesDialogData.title = '修改模版'
      } else {
        submitSmesDialogForm.templateName = ''
        submitSmesDialogForm.title = ''
        submitSmesDialogForm.content = ''
        smesDialogData.templateId = ''
      }
      smesDialogRef.value.showDialog = true
    }
    const submitSmesDialogFormRef = ref()
    const submitSmesDialogForm = reactive({
      templateName: '',
      title: '',
      content: ''
    })
    const submitSmesDialogFormRules = {
      templateName: [{ required: true, message: '请输入模版名称 ', trigger: 'blur' }],
      title: [{ required: true, message: '请输入推送标题 ', trigger: 'blur' }],
      content: [{ required: true, message: '请输入推送内容 ', trigger: 'blur' }]
    }
    // 确认发送
    const submitSmesDialog = () => {
      submitSmesDialogFormRef.value.CustomFormRef.validate()
        .then(() => {
          const nParams = searchParams(submitSmesDialogForm)
          if (smesDialogData.title === '修改模版') {
            nParams.templateId = smesDialogData.templateId
          }
          dispatch(smesDialogData.paramsName, nParams).then(async data => {
            if (data && data.code === 200) {
              commit('setError', {
                status: true,
                title: data.message,
                message: '正在获取新的数据...',
                type: 'success'
              })
              tabberData.page === 1 ? getLogTabberData() : (tabberData.page = 1)
              smesDialogRef.value.showDialog = false
            }
          })
        })
        .catch(() => {})
    }

    // 删除
    const deleteSmsTemplatesDialogRef = ref()
    const deleteSmsTemplatesDialogData = reactive({
      templateId: ''
    })
    const clickDelete = (index, row) => {
      deleteSmsTemplatesDialogData.templateId = row.templateId
      deleteSmsTemplatesDialogRef.value.showDialog = true
    }
    const deleteSmsTemplatesDialogSubmit = () => {
      dispatch('fetchDeleteSmsTemplate', deleteSmsTemplatesDialogData.templateId).then(
        async data => {
          if (data && data.code === 200) {
            commit('setError', {
              status: true,
              title: data.message,
              message: '正在获取新的数据...',
              type: 'success'
            })
            tabberData.page === 1 ? getLogTabberData() : (tabberData.page = 1)
            deleteSmsTemplatesDialogRef.value.showDialog = false
          }
        }
      )
    }

    onMounted(async () => {
      getLogTabberData()
    })
    return {
      searchDataRef,
      searchData,
      resetSearchData,
      searchFn,
      tabberData,
      currentChange,
      sizeChange,
      tabberHeight,
      clickSmes,
      smesDialogRef,
      submitSmesDialog,
      submitSmesDialogForm,
      submitSmesDialogFormRef,
      smesDialogData,
      submitSmesDialogFormRules,
      clickEdit,
      deleteSmsTemplatesDialogRef,
      deleteSmsTemplatesDialogSubmit,
      clickDelete
    }
  }
}
</script>

<style lang="scss" scoped>
.log-manage {
  @extend %params-global;
}
</style>
